import React, { Component } from "react";

let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/portfolio/" + projects.image;

      // CSS for the images
      const imageStyle = {
        height: "200px", // Standardize height
        width: "auto", // Allow width to adjust to maintain aspect ratio
        objectFit: "cover", // Ensures image covers the area, may crop if necessary
        display: "block", // Ensures the image is block level
        marginLeft: "auto", // Centers the image horizontally if its width is less than container's width
        marginRight: "auto", // Same as above
        maxWidth: "100%", // Ensures image does not exceed its container's width
      };

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <a
              href={projects.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textAlign: "center",
                display: "block",
                color: "inherit",
              }}
            >
              <p style={{ textAlign: "center" }}>{projects.category}</p>
              <img src={projectImage} alt={projects.title} style={imageStyle} />
            </a>
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>some of my work</h1>

            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {projects}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
