import React, { Component } from "react";

let id = 0;

class Blog extends Component {
  render() {
    if (!this.props.data) return null;

    const entries = this.props.data.entries.map((entry) => {
      let projectImage = `images/blog/${entry.image}`;
      // Assuming the `entry` object includes a `filename` that corresponds to the HTML document.
      let entryUrl = `${process.env.PUBLIC_URL}/blog/entries/${entry.filename}`;

      const imageStyle = {
        height: "200px",
        width: "auto",
        objectFit: "cover",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100%",
      };

      return (
        <div key={id++} className="columns blog-item">
          <div className="item-wrap">
            {/* Using an anchor tag to link directly to the HTML document */}
            <a
              href={entryUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textAlign: "center",
                display: "block",
                color: "inherit",
              }}
            >
              <p style={{ textAlign: "center" }}>{entry.category}</p>
              <img src={projectImage} alt={entry.title} style={imageStyle} />
            </a>
          </div>
        </div>
      );
    });

    return (
      <section id="blog">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Blog entries</h1>
            <div id="blog-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {entries}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog;
